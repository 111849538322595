import React from "react";
import PurchaseOrderCore from "./PurchaseOrderCore";
import SuperMarketPurchaseOrder from "./SuperMarketPurchaseOrder";

const PurchaseOrder = () => {
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const tillaccess = JSON.parse(tillData?.tillAccess?.userAccessController);
  const tillLayout = parseInt(tillaccess?.layout === null || undefined ? 1 : tillaccess?.layout);
  return tillaccess.pos === "Y" ? <PurchaseOrderCore component={SuperMarketPurchaseOrder} /> : <h1>Access Denied</h1>;
};

export default PurchaseOrder;
