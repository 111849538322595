import React from "react";
import { useEffect, useState, useRef } from "react";
import Axios from "axios";
import db from "../../../database";
import { getOAuthHeaders } from "../../../constants/oAuthValidation";
import ModalComponent from "./ModalComponent";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
const InventoryCore = (props) => {
  const RenderComponent = props.component;
  const colorVariable = process.env.REACT_APP_POSBACKGROUND_COLOR;
  const domainURL = process.env.REACT_APP_domain;
  const retailUrl = process.env.REACT_APP_serverUrl;
  const SSOURL = process.env.REACT_APP_SSOURL;
  const CWCoreServicesUrl = process.env.REACT_APP_genericUrl;
  const redirectUrl = process.env.REACT_APP_redirectURL;
  const { access_token, username } = getOAuthHeaders();
  const userData = JSON.parse(localStorage.getItem("userData"));
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const textAreaRef = useRef(null);
  const keyboardRef = useRef(null);
  const scrollContainerRef = useRef(null);
  const tableBodyRef = useRef(null);
  const dateRef = useRef(null);
  const remarksRef = useRef(null);
  const [comments, setComments] = useState("");
  const [tempComments, setTempComments] = useState("");
  const [showComments, setShowComments] = useState(false);
  const [layoutName, setLayoutName] = useState("default");
  const [showTargetLocation, setShowTargetLocation] = useState(false);
  const [addStock, setAddStock] = useState(false);
  const [tillLink, setTillLink] = useState(false);
  const [isLocked, setIsLocked] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [categoriesData, setCategoriesData] = useState([]);
  const [showArrows, setShowArrows] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const [productsList, setProductsList] = useState([]);
  const [filteredProductsList, setFilteredProductsList] = useState([]);
  const [startRowData, setStartRowData] = useState({ startRow: "0", endRow: "10" });
  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [displayedData, setDisplayedData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [itemsToShow, setItemsToShow] = useState(40); // Initial number of items to show
  const [loading, setLoading] = useState(false);
  const [showRemarks, setShowRemarks] = useState(false);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [remarks, setRemarks] = useState("");
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [warehouseData, setWarehouseData] = useState([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState("");
  const [isFocusTriggered, setIsFocusTriggered] = useState(false);
  const formattedDateTime = `${currentDateTime.toLocaleTimeString()} | ${currentDateTime.toLocaleDateString("en-US", {
    weekday: "long", // Full day name (e.g., Wednesday)
    day: "numeric", // Day of the month (e.g., 28)
    month: "long", // Full month name (e.g., June)
    year: "numeric", // Full year (e.g., 2023)
  })}`;
  const [productionNextNo, setProductionNextNo] = useState(() => {
    const nextNo = localStorage.getItem("productionNextNo");
    if (nextNo) {
      return nextNo;
    } else {
      return "";
    }
  });
  const [requestQty, setRequestQty] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [productSearchInput, setProductSearchInput] = useState("");
  const [remarksPopup, setRemarksPopup] = useState(false);
  const [remarksTextArea, setRemarksTextArea] = useState("");
  const [productionTypeData, setProductionTypeData] = useState({});
  const [inventoryHistoryData, setInventoryHistoryData] = useState([]);

  const [inventoryOrder, setInventoryOrder] = useState({
    items: [],
    stockRequestID: uuidv4().replace(/-/g, "").toUpperCase(),
    transactionQueueId: uuidv4().replace(/-/g, "").toUpperCase(),
    timestamp: moment().format("YYYY-MM-DD HH:mm:ss"),
    user_id: userData?.user_id,
    name: userData?.name,
    csBunitId: tillData?.tillAccess?.csBunit?.csBunitId,
    fromWarehouseId: tillData?.tillAccess?.csBunit?.mWarehouse?.mWarehouseID,
    toWarehouseId: null,
  });

  useEffect(() => {
    if (showComments && textAreaRef.current) {
      setTimeout(() => textAreaRef.current.focus(), 0);
    }
    const amountInput = document.getElementById("sm-notes-input");
    if (amountInput) {
      amountInput?.focus();
    }
  }, [showComments]);

  useEffect(() => {
    if (showRemarks) {
      setTimeout(() => {
        remarksRef?.current.focus();
      }, 100);
    }
  }, [showRemarks]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const categories = await db.productCategories.toArray();
  //     const products = await db.products.toArray();

  //     const modifiedProducts = products.map((product) => {
  //       const matchedCategory = categories.find((category) => category.mProductCategoryId === product.mProductCategoryId);
  //       return {
  //         ...product,
  //         productCategoryName: matchedCategory ? matchedCategory.name : " ",
  //       };
  //     });

  //     const sortedCategories = categories
  //       .map((item) => ({
  //         ...item,
  //         label: item.value,
  //         value: item.mProductCategoryId,
  //       }))
  //       .sort((a, b) => a.label.localeCompare(b.label));

  //     setCategoriesData(sortedCategories);
  //     setProductsList(modifiedProducts);
  //     setFilteredProductsList(modifiedProducts);
  //   };
  //   fetchData();
  // }, []);

  useEffect(async () => {
    await db.docTypesData.toArray().then((docTypes) => {
      const requiredDocTypes = docTypes.filter((docType) => docType?.doccategory === "STR");
      let newOrder = JSON.parse(localStorage.getItem("inventoryOrderData")) || inventoryOrder;
      newOrder.indentNo = `${requiredDocTypes[0]?.prefix || ""}${requiredDocTypes[0]?.nextno}`;
      setInventoryOrder(newOrder);
      localStorage.setItem("inventoryOrderData", JSON.stringify(newOrder));
      setProductionTypeData(requiredDocTypes);
      setProductionNextNo(`${requiredDocTypes[0]?.prefix || ""}${requiredDocTypes[0]?.nextno}`);
    });
  }, []);

  useEffect(() => {
    const checkArrowsVisibility = () => {
      if (scrollContainerRef.current) {
        const containerWidth = scrollContainerRef.current.offsetWidth;
        const contentWidth = scrollContainerRef.current.scrollWidth;
        setShowArrows(contentWidth > containerWidth);
      }
    };
    checkArrowsVisibility();
    window.addEventListener("resize", checkArrowsVisibility);

    return () => {
      window.removeEventListener("resize", checkArrowsVisibility);
    };
  }, [addStock, categoriesData]);

  const scrollLeft1 = () => {
    scrollContainerRef.current.scrollBy({ left: -200, behavior: "smooth" });
  };

  const scrollRight = () => {
    scrollContainerRef.current.scrollBy({ left: 200, behavior: "smooth" });
  };

  const handleButtonClick = (categoryValue) => {
    let updatedSelectedCategories;

    if (categoryValue === "ALL") {
      updatedSelectedCategories = [];
    } else {
      updatedSelectedCategories = [...selectedCategories];
      if (updatedSelectedCategories.includes(categoryValue)) {
        const index = updatedSelectedCategories.indexOf(categoryValue);
        updatedSelectedCategories.splice(index, 1);
      } else {
        updatedSelectedCategories.push(categoryValue);
      }
    }

    setSelectedCategories(updatedSelectedCategories);

    let filteredList;

    if (updatedSelectedCategories.length === 0 || categoryValue === "ALL") {
      filteredList = [...productsList];
    } else {
      filteredList = productsList.filter(
        (product) => updatedSelectedCategories.includes(product.m_product_category_id) || (requestQty[product.productId] && Number(requestQty[product.productId]) > 0) // Include products with non-zero requestQty
      );
    }

    filteredList.sort((a, b) => {
      const qtyA = requestQty[a.id] || 0;
      const qtyB = requestQty[b.id] || 0;
      if (qtyA > 0 && qtyB === 0) return -1;
      if (qtyA === 0 && qtyB > 0) return 1;
      return 0;
    });

    setFilteredProductsList(filteredList);
  };

  const handleAddProducts = () => {
    setAddStock(false);
    const productsToAdd = displayedData.filter((product) => {
      const qty = requestQty[product.productId];
      return qty && Number(qty) > 0;
    });

    setInventoryOrder((prev) => ({
      ...prev,
      items: [
        ...(prev.items || []),
        ...productsToAdd.map((product, index) => ({
          ...product,
          requestQty: parseFloat(requestQty[product.productId]),
          key: (prev.items?.length || 0) + index + 1,
        })),
      ],
    }));

    // Remove added products from requestQty
    const updatedRequestQty = { ...requestQty };
    productsToAdd.forEach((product) => {
      delete updatedRequestQty[product.productId];
    });

    setRequestQty(updatedRequestQty);
  };

  const getInventoryProducts = async () => {
    try {
      // Proper JSON formatting
      const filterData = JSON.stringify({
        businessUnit: tillData?.tillAccess?.csBunit?.csBunitId,
        storageBin: "",
        productCategory: "",
      });

      const sortBy = JSON.stringify({ dateOrdered: "desc" });

      // Correct GraphQL query string construction
      const paramsInput = {
        query: `query {
          getInventoryProducts(
            q: "",
            query_by: [],
            filter_by: "${filterData.replace(/"/g, '\\"')}",
            sort_by: "${sortBy.replace(/"/g, '\\"')}",
            limit: 50,
            offset: 0
          )
        }`,
      };

      // API request
      const inventoryDataResponse = await Axios({
        url: retailUrl,
        method: "POST",
        data: paramsInput,
        headers: {
          "Content-Type": "application/json",
          Authorization: `${access_token}`,
        },
      });

      // Parsing the response
      const parsedData = JSON.parse(inventoryDataResponse.data.data.getInventoryProducts);
      console.log(parsedData, "00parsedData");
      setProductsList(parsedData);
      setFilteredProductsList(parsedData);
      setAddStock(true);
    } catch (err) {
      console.log("Error fetching warehouse data", err);
    }
  };

  const getInventoryCategory = async () => {
    try {
      // Proper JSON formatting
      const filterData = JSON.stringify({ bunitId: tillData?.tillAccess?.csBunit?.csBunitId });
      const newStringifiedFields = filterData.replace(/\\"/g, '\\"');
      const newStringRequest = JSON.stringify(newStringifiedFields);
      // Correct GraphQL query string construction
      const paramsInput = {
        query: `mutation { executeAPIBuilder(apiBuilderId:"6715ee604a4b5c65a6d8a606", params: ${newStringRequest})}`,
      };

      // API request
      const inventoryDataResponse = await Axios({
        url: CWCoreServicesUrl,
        method: "POST",
        data: paramsInput,
        headers: {
          "Content-Type": "application/json",
          Authorization: `${access_token}`,
        },
      });

      // Parsing the response
      const parsedData = JSON.parse(inventoryDataResponse.data.data.executeAPIBuilder);
      console.log(parsedData, "rsedData");
      setCategoriesData(parsedData);
      setAddStock(true);
    } catch (err) {
      console.log("Error fetching warehouse data", err);
    }
  };

  const getInventoryHistory = async () => {
    try {
      // Proper JSON formatting
      const filterData = JSON.stringify({ warehouseId: inventoryOrder.toWarehouseId || "", bunitId: tillData?.tillAccess?.csBunit?.csBunitId, limit: "", offset: "" });
      const newStringifiedFields = filterData.replace(/\\"/g, '\\"');
      const newStringRequest = JSON.stringify(newStringifiedFields);
      // Correct GraphQL query string construction
      const paramsInput = {
        query: `mutation { executeAPIBuilder(apiBuilderId:"675820fce81ec1579ee548c7", params: ${newStringRequest})}`,
      };

      // API request
      const inventoryDataResponse = await Axios({
        url: CWCoreServicesUrl,
        method: "POST",
        data: paramsInput,
        headers: {
          "Content-Type": "application/json",
          Authorization: `${access_token}`,
        },
      });

      // Parsing the response
      const parsedData = JSON.parse(inventoryDataResponse.data.data.executeAPIBuilder);
      console.log(parsedData, "rsedData");
      const dataWithIds = parsedData.map((item) => ({
        ...item,
        key: uuidv4().replace(/-/g, "").toUpperCase(), // Adding a unique id
      }));

      setInventoryHistoryData(dataWithIds);
    } catch (err) {
      console.log("Error fetching warehouse data", err);
    }
  };

  const handleShift = () => {
    layoutName === "default" ? setLayoutName("shift") : setLayoutName("default");
  };

  // const handleSearch = (e) => {
  //     setSearchQuery(e.target.value);
  // };

  useEffect(() => {
    const filteredData = filteredProductsList.filter((product) => {
      const searchInFields = [
        product.name?.toLowerCase(),
        // product.productCategoryName?.toLowerCase(),
        // product.productBrandName?.toLowerCase(),
        product.value?.toString(),
        // product.uomData?.[0]?.ediCode?.toLowerCase(),
      ];
      return searchInFields.some((field) => field?.includes(searchQuery));
    });
    setDisplayedData(filteredData);
  }, [searchQuery, filteredProductsList]);

  const tillUnlink = async () => {
    const unlinkTillAPI = async () => {
      try {
        let id = tillData.tillAccess.cwrTill.cwrTillID;
        const response = await Axios({
          url: retailUrl,
          method: "POST",
          data: {
            query: `mutation {
                      unlinkTill(tillId: "${id}") {
                        status
                        message
                      } 
                    }`,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `${access_token}`,
          },
        });

        if (response.data.data.unlinkTill.status === "200") {
          const keepKey = "";
          localStorage.clear();
          await Promise.all(
            db.tables.map(async (table) => {
              if (table.name !== keepKey) {
                await table.clear();
              }
            })
          );
        }

        localStorage.removeItem("unlink");
        window.location.assign(`${SSOURL}sign-out/?redirect_uri=${redirectUrl}`);
      } catch (error) {
        console.error("Error in unlinkTillAPI:", error);
      }
    };
    await unlinkTillAPI();
    // }
  };

  useEffect(() => {
    if (!localStorage.getItem("dataLength")) {
      localStorage.setItem("dataLength", "10");
    }
  }, []);

  let scrollLeft;
  let maxScroll;
  let currentScroll;

  const fetchMoreData = (event) => {
    const maxScroll = event.target.scrollHeight - event.target.clientHeight;
    const currentScroll = event.target.scrollTop;

    if (Math.round(currentScroll) >= Math.round(maxScroll) - 1 && !loading) {
      setLoading(true);

      setTimeout(() => {
        const newItemsToShow = itemsToShow + 50;
        setItemsToShow(newItemsToShow);
        setDisplayedData(filteredProductsList.slice(0, newItemsToShow));
        setLoading(false);
      }, 300);
    }
  };

  useEffect(() => {
    setDisplayedData(filteredProductsList.slice(0, itemsToShow));
  }, [filteredProductsList, itemsToShow]);

  useEffect(() => {
    const tableBody = tableBodyRef.current;
    if (tableBody) {
      tableBody.addEventListener("scroll", fetchMoreData);
      return () => {
        tableBody.removeEventListener("scroll", fetchMoreData);
      };
    }
  }, [fetchMoreData]);

  //   const handleFocus = (record, index) => {
  //     if (!showRemarks && !isFocusTriggered) {
  //       setSelectedRowIndex(index);
  //       setRemarks(record.remarks || ""); // Set remarks value for the modal
  //       setShowRemarks(true);
  //       setIsFocusTriggered(true);
  //     }
  //   };

  //   const handleSaveRemarks = () => {
  //     if (selectedRowIndex !== null) {
  //       addedProducts[selectedRowIndex].remarks = remarks; // Update the record with the new remarks
  //     }
  //     setShowRemarks(false);
  //     setIsFocusTriggered(false);
  //   };

  //   const handleCancel = () => {
  //     setShowRemarks(false);
  //     setIsFocusTriggered(false);
  //   };

  const handleFocus = (record, index) => {
    if (!showRemarks && !isFocusTriggered) {
      setSelectedRowIndex(index);
      setRemarks(record.remarks || "");
      setShowRemarks(true);
      setIsFocusTriggered(true);
    }
  };

  const handleSaveRemarks = () => {
    if (selectedRowIndex !== null) {
      inventoryOrder.items[selectedRowIndex].remarks = remarks;
    }
    setShowRemarks(false);
    setIsFocusTriggered(false);
  };

  const handleCancel = () => {
    setShowRemarks(false);
    setIsFocusTriggered(false);
  };

  // const handleRequestQtyChange = (value, record) => {
  //   const updatedData = addedProducts.map((item) => (item.key === record.key ? { ...item, requestQty: value } : item));
  //   setAddedProducts(updatedData);
  // };

  const handleRequestQtyChange = (value, record) => {
    setInventoryOrder((prev) => ({
      ...prev,
      items: prev.items.map((item) => (item.key === record.key ? { ...item, requestQty: value } : item)),
    }));
  };

  const componentProps = {
    loading,
    colorVariable,
    addStock,
    setAddStock,
    searchQuery,
    setSearchQuery,
    showArrows,
    scrollLeft1,
    scrollRight,
    scrollContainerRef,
    handleButtonClick,
    selectedCategories,
    categoriesData,
    tableBodyRef,
    setRequestQty,
    requestQty,
    displayedData,
    setSelectedCategories,
    handleAddProducts,
    showHistory,
    setShowHistory,
    setTillLink,
    productionNextNo,
    formattedDateTime,
    setIsLocked,
    userData,
    setProductSearchInput,
    productSearchInput,
    handleRequestQtyChange,
    handleFocus,
    setShowComments,
    showComments,
    comments,
    setComments,
    setTempComments,
    tempComments,
    keyboardRef,
    setLayoutName,
    layoutName,
    tillData,
    domainURL,
    showRemarks,
    setRemarks,
    remarks,
    handleSaveRemarks,
    remarksRef,
    textAreaRef,
    setShowTargetLocation,
    showTargetLocation,
    tillLink,
    tillUnlink,
    handleCancel,
    handleShift,
    remarksPopup,
    setRemarksPopup,
    isInputFocused,
    setIsInputFocused,
    remarksTextArea,
    setRemarksTextArea,
    selectedRowIndex,
    setSelectedRowIndex,
    warehouseData,
    setWarehouseData,
    selectedWarehouse,
    setSelectedWarehouse,
    inventoryOrder,
    setInventoryOrder,
    productionTypeData,
    getInventoryProducts,
    getInventoryCategory,
    getInventoryHistory,
    inventoryHistoryData,
    setInventoryHistoryData,
  };

  return (
    <div>
      <RenderComponent {...componentProps} />
      <ModalComponent {...componentProps} />
    </div>
  );
};

export default InventoryCore;
