import React, { Fragment, useContext, useState, useEffect, useRef, createContext, Component } from "react";
import { useHistory } from "react-router-dom";
import { Modal, Row, Col, Card, Tooltip, Table, Divider, Button, Input, Menu, Spin, Badge, message, Select, DatePicker, Form, Tabs } from "antd";
import {
  SearchOutlined,
  ShoppingCartOutlined,
  CloseOutlined,
  ArrowLeftOutlined,
  LoadingOutlined,
  CloseSquareFilled,
  InfoOutlined,
  LeftOutlined,
  RightOutlined,
  PlusOutlined,
  MinusOutlined,
  DownOutlined,
  DownloadOutlined,
  UserOutlined,
  TagsOutlined,
  PrinterOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import "react-aspect-ratio/aspect-ratio.css";
import "../../../style.css";
import "../styles.css";
import "../../../globalStyles.css";
import { useWindowDimensions } from "../../../../lib/useViewport";
import BarCodeComponentForRetail from "../../../../lib/BarCodeComponentForRetail";
import db from "../../../../database";
import { v4 as uuidv4 } from "uuid";
import { processSync } from "../../../Sync/syncData";
import Axios from "axios";
import { getOAuthHeaders } from "../../../../constants/oAuthValidation";
import kisokLogo from "../../../../assets/images/kioskLogo.svg";
import mobileImg from "../../../../assets/images/mobile.svg";
import emailImg from "../../../../assets/images/email.svg";
import volumeImg from "../../../../assets/images/volume.svg";
import wheelchairImg from "../../../../assets/images/wheelchair.svg";
import helpImg from "../../../../assets/images/help.svg";
import kioskBGI from "../../../../assets/images/kioskBGI.png";

import userIcon from "../../../../assets/images/userId.svg";
import POSLogo from "../../../../assets/images/NewLogoCW.svg";
import SearchIcon from "../../../../assets/images/searchIcon.svg";
import FilterIcon from "../../../../assets/images/filterIcon.svg";
import AddUser from "../../../../assets/images/userAdd.svg";
import Home from "../../../../assets/images/home-outline.svg";
import Email from "../../../../assets/images/emailNew.svg";
import Mobile from "../../../../assets/images/callNew.svg";
import CustomerIcon from "../../../../assets/images/customer1.svg";
import QRCode from "../../../../assets/images/qr-code-scan.svg";
import CardIcon from "../../../../assets/images/card-outline.svg";
import CashIcon from "../../../../assets/images/rupee.svg";
import Retrieve from "../../../../assets/images/retrieve.png";
import { htmlPrint } from "../../../../lib/printer/htmlPrinter";
import { xmlPrint } from "../../../../lib/printer/xmlPrinter";
import printerIcon from "../../../../assets/images/printerIcon.svg";
import Discard from "../../../../assets/images/returnIcon.svg";
import DownloadIcon from "../../../../assets/images/downloadIcon.png";
import Cancel from "../../../../assets/images/cancel-new.svg";
import Hold from "../../../../assets/images/pause-new.svg";
import POSIcon from "../SvgComponent/POS";
import ParkedBillIcon from "../SvgComponent/ParkedBill";
import CashManagementIcon from "../SvgComponent/CashManagementIcon";
import SalesHistoryIcon from "../SvgComponent/SalesHistoryIcon";
import data from "../../../../constants/retailPos.json";
import Offers from "../../../../assets/images/offers.png";
import CancelArrow from "../../../../assets/images/cancelArrow.svg";
import DeleteProduct from "../../../../assets/images/delete1.svg";
import discount from "../../../../assets/images/discount.png";
import SyncIcon from "../../../../assets/images/syncIcon.svg";
import ReturnIcon from "../../../../assets/images/returnIcon.svg";
import Logout from "../../../../assets/images/logout-new.svg";
import _ from "lodash";
import "./style.css";
import { useTranslation } from "react-i18next";
import MobileComponent from "./MobileComponent";
import EmailComponent from "./EmailComponent";
import HomeComponent from "./HomeComponent";
import PaymentSelection from "./PaymentSelection";
import Login from "./Login";
import AdminComponent from "./AdminComponent";
import defaultImage from "../../../../assets/images/no-image.svg";
const serverUrl = process.env.REACT_APP_serverUrl;

const EditableContext = createContext(null);
const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({ title, editable, children, dataIndex, record, handleSave, setModalEditState, ...restProps }) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  const { t } = useTranslation();

  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    setModalEditState(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const checkManualQty = (value) => {
    if (!Number.isInteger(value)) {
      if (record.product.isManualQty) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      values.qtyToReturn = parseFloat(values.qtyToReturn);
      let returnTotal = record.qty - record.returnQty;
      if (values.qtyToReturn > returnTotal) {
        {
          message.warning(`${t("sales_return_error")}`);
        }
        throw new Error("Qty is more than ordered, invalid value");
      } else if (checkManualQty(values.qtyToReturn)) {
        message.warning("Return Qty can't be a Decimal Value !");
        throw new Error("Qty is not a valid type, invalid value");
      } else {
        toggleEdit();
        handleSave({ ...record, ...values });
      }
    } catch (errInfo) {
      console.error("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input ref={inputRef} type="number" onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div className="editable-cell-value-wrap" onClick={toggleEdit}>
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

const Kiosk = (props) => {
  const { t } = useTranslation();
  const dynamicHeight = 0.5625;
  const posConfig = JSON.parse(localStorage.getItem("posConfig"));
  const {
    isCardPaymentLoading,
    setIsCardPaymentLoading,
    addProduct,
    cart,
    setCart,
    checkIsManualWeight,
    clearProductSearchResults,
    closeProductPanel,
    confirmDiscardCart,
    decreaseProductQty,
    deleteCart,
    deleteProduct,
    displayClock,
    getMoreProducts,
    getSearchedProducts,
    increaseProductQty,
    isProductsFilter,
    isQtyUpdate,
    isSearchProducts,
    loader,
    onBarcodeInput,
    openDisplayParkedBillModal,
    openPaymentModal,
    orderType,
    parkBill,
    parkedList,
    productCategories,
    productSearchInput,
    productSearchInputRef,
    productsList,
    // setProductsList,
    // selectProductCategory,
    selectProductInCart,
    selectProduct,
    selectedProductInCart,
    selectedRowKeys,
    setSelectedRowKeys,
    selectedKeys,
    setDisplayCustomerSearch,
    setCloseCustomerFlag,
    setDisplayOrderType,
    setIsProductsFilter,
    setIsQtyUpdate,
    setIsSearchProducts,
    setProductSearchInput,
    showOrderHistory,
    tillData,
    openSideMenu,
    currencyType,
    setSalesRepModalOpen,
    salesRepresent,
    setSalesRepresent,
    setSalesRepresentSearchInput,
    setFilteredSalesRepresentList,
    salesReprestiveList,
    setManualDiscountInput,
    setManualDiscountModalVisible,
    clearSelectedProductInCart,
    productListCardRef,
    posLogActivity,
    removeCutomer,
    // parked Data
    displayParkedBillModal,
    closeParkedBillModal,
    handleParkedBillSearchInput,
    searchParkedBill,
    parkedBillSearchInput,
    filterdParkedList,
    discardParkedBill,
    selectParkedBill,
    // Cash Management
    setAddCashFlag,
    // Order History
    setOrderHistoryInput,
    searchOrderHistory,
    changeOrderHistorySearchType,
    orderHistoryInput,
    orderHistorySearchInputRef,
    orderHistoryDetails,
    setOrderHistoryDetails,
    paymentModal,
    tillDataPaymentMethods,
    requestPayment,
    setCouponModalVisible,
    paymentCardRef,
    setSelectedPaymentMethod,
    selectedPaymentMethod,
    amount,
    onChangeAmount,
    paymentModalInputRef,
    processOrder,
    paymentModalLoyalityMessages,
    handleAmount,
    selectedProductCategory,
    setCashAddInFlag,
    addCashFlag,
    isPrintModeXML,
    setSelectedKeys,
    setDisplayReturnOrderSearch,
    displayReturnOrderSearch,
    getSearchedItem,
    isProductsVisible,
    setIsProductsVisible,
    // customer
    handleCustomerSearch,
    setCustomerSearchInput,
    customerSearchResults,
    setKioskUI,
    kioskLogin,
    form,
    kioskUI,
    addNewCustomer,
    layoutType,
    setLayoutType,
    kioskFilteredProducts,
    openPaymentModalByCustomer,
    setIsInputFocused,
    isInputFocused,
    pickProduct,
  } = props;
  const currenciesList = tillData.tillAccess.csBunit.currencies;
  const customerId = tillData.tillAccess.csBunit.b2cCustomer.cwrCustomerId;
  const themeJSON = posConfig?.themeJSON && posConfig?.themeJSON !== "N" && Object.keys(posConfig.themeJSON).length > 0 ? JSON.parse(posConfig.themeJSON)?.layout3 : {};
  const isThemeLogo = themeJSON.logoURL;
  const logoURL = isThemeLogo ? themeJSON.logoURL : kisokLogo;

  const [tableValue, setTableValue] = useState("POS");
  const [keyValue, setKeyValue] = useState("0");
  const { width } = useWindowDimensions();
  const [cashManagementData, setCashManagementData] = useState(false);
  // const [productArray, setProductArray] = useState([...productsList]);
  const [productItems, setProductItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showReturnPurchase, setShowReturnPurchase] = useState(false);
  const [paymentType, setPaymentType] = useState(tillDataPaymentMethods);
  const [isParkedBill, setIsparkedBill] = useState(false);
  const [isPurchaseReturn, setIsPurchaseReturn] = useState(false);
  const [isCashMangement, setIsCashManagement] = useState(false);
  const [returnFlag, setReturnFlag] = useState(false);
  const [value, setValue] = useState(0);
  const [categoriesData, setCategoriesData] = useState([]);
  const history = useHistory();
  const [isLocked, setIsLocked] = useState(false);

  // let kioskUI = parseFloat(localStorage.getItem("kioskUI"));

  // useEffect(() => {
  //   const syncOrdersInterval = setInterval(() => kioskUI = parseFloat(), 200);
  //   return () => {
  //     clearTimeout(syncOrdersInterval);
  //   };
  // }, [customerSearchResults]);

  // Keyboard Changes
  const [inputFields, setInputFields] = useState({});
  const [inputName, setInputName] = useState("default");
  const keyboardRef = useRef(null);
  const inputRef = useRef(null);
  const [layoutName, setLayoutName] = useState("default");
  const [emailLogin] = Form.useForm();

  const handleKeyboardInputs = (button) => {
    if (button === "{shift}" || button === "{caps}") setLayoutName("shift");
    if (button === "{default}" || button === "{small}") setLayoutName("default");
    if (button === "{numbers}") setLayoutName("numbers");
    if (button === "{number}") setLayoutName("number");
  };

  const onChange = (inputs) => {
    setInputFields({ ...inputs });
    kioskLogin.setFieldsValue(inputs);
  };

  // Return Bill Start
  const [retrunSearchType, setReturnSearchType] = useState("orderNo");
  const [selectedReturnOrder, setSelectedReturnOrder] = useState({});
  const [modalEditState, setModalEditState] = useState(false);

  const [dataSource, setDataSource] = useState([]);

  const searchReturnHistory = async (documentno) => {
    const searchQuery = `${retrunSearchType} : "${documentno}"`;
    const authHeaders = getOAuthHeaders();
    if (authHeaders && authHeaders.access_token) {
      try {
        // setLoader(true);
        const searchData = await Axios({
          url: serverUrl,
          method: "POST",
          data: {
            query: `query {
                      verifyReturn(${searchQuery}) {
                          sOrderID
                          created
                          createdby
                          updated
                          updatedby
                          documentno
                          dateordered
                          totalQty
                          orderTime
                          cwrProductQty
                          taxamt
                          grosstotal
                          discAmount
                          csBUnit {
                              csBunitId
                              name
                          }
                          cwrB2cCustomer {
                              cwrCustomerId
                              code
                              name
                              mobileNo
                              pincode
                              email
                              retlLoyaltyBalance
                              sCustomer {
                                  sCustomerID
                                  customerCategory {
                                      sCustomerCateforyId
                                      value
                                      name
                                      description
                                  }
                              }
                          }
                          saleType {
                              cwrSaletypeId
                              name
                              value
                          }
                          cwrTill {
                              cwrTillID
                              till
                          }
                          finReceiptPlan {
                              finReceiptPlanDetails {
                                  amount
                                  cwrPaymentmethod {
                                      cWRPaymentMethodID
                                      finFinancialAccountId
                                      finPaymentmethodId
                                      integratedPayment
                                      isloyalty
                                      paymentProvider
                                  }
                              }
                          }
                          line {
                              sOrderlineID
                              sOrderId
                              line
                              description
                              qty
                              netlist
                              netunit
                              linetax
                              unittax
                              linenet
                              linegross
                              grosslist
                              grossstd
                              returnline
                              returnQty
                              discount
                              product {
                                  mProductId
                                  name
                                  value
                                  upc
                                  hsncode
                                  imageurl
                                  isManualQty
                                  shortDescription
                                  returnable
                                  returnDays
                              }
                              uom {
                                  csUomId
                                  name
                              }
                              tax {
                                  csTaxID
                                  name
                                  rate
                              }
                              pricingRule {
                                  mPricingrulesId
                                  name
                              }
                          }
                      }
                  }`,
          },
          headers: {
            "Content-Type": "Application/json",
            Authorization: `${authHeaders.access_token}`,
          },
        });
        selectPurchaseBillForReturn(searchData.data.data.verifyReturn);
        setShowReturnPurchase(true);
      } catch (error) {
        console.error(error);
      } finally {
        // setLoader(false);
      }
    }
  };

  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });
    setDataSource(newData);
    handleSaveReturnCartRecord(newData);
    setTimeout(() => {
      returnProcessRef.current.focus();
    }, 750);
  };

  let index = 0;
  const selectPurchaseBillForReturn = (data) => {
    if (index === 0) {
      let returnBillItem = data[0];
      setDisplayReturnOrderSearch(false);
      setReturnSearchType("orderNo");

      const discountValue = returnBillItem.discAmount;
      let grossstdTotal = 0;
      for (let i = 0; i < returnBillItem.line.length; i += 1) {
        grossstdTotal += parseFloat(returnBillItem.line[i].grossstd);
      }
      const zeroDiscountCount = returnBillItem.line.filter((item) => item.discount === 0).length;

      if (zeroDiscountCount === returnBillItem.line.length && discountValue > 0) {
        returnBillItem.line.forEach((addedToCart, index) => {
          const discountAmt = parseFloat((addedToCart.grossstd / grossstdTotal) * discountValue) / addedToCart.qty;
          const sp = parseFloat(addedToCart.grossstd) - discountAmt;
          addedToCart.grossstd = sp;
          returnBillItem.line[index] = addedToCart;
        });
      }
      const updatedDataSource = [...returnBillItem.line];

      updatedDataSource.forEach((_, i) => {
        updatedDataSource[i].key = `${i}`;
        updatedDataSource[i].qtyToReturn = updatedDataSource[i].qtyToReturn ? updatedDataSource[i].qtyToReturn : 0;
      });

      updatedDataSource.itemsToReturn = 0;
      updatedDataSource.itemsQtyToReturn = 0;
      updatedDataSource.returnAmountTotal = 0;

      setDataSource([...updatedDataSource]);
      setSelectedReturnOrder({ ...returnBillItem });
      index++;
    }
  };

  const handleSaveReturnCartRecord = (data) => {
    let itemsToReturn = 0;
    let itemsQtyToReturn = 0;
    let returnAmountTotal = 0;

    data.forEach((item, i) => {
      if (item.qtyToReturn > 0) {
        itemsToReturn += 1;
        itemsQtyToReturn += item.qtyToReturn;
        returnAmountTotal += item.qtyToReturn * item.grossstd;
      }
    });

    selectedReturnOrder.line = data;
    selectedReturnOrder.itemsToReturn = itemsToReturn;
    selectedReturnOrder.itemsQtyToReturn = itemsQtyToReturn;
    selectedReturnOrder.returnAmountTotal = returnAmountTotal;
    setSelectedReturnOrder({ ...selectedReturnOrder });
  };

  const addSelectedReturnProductsToCart = async () => {
    const customerResult = await getCustomerData(selectedReturnOrder.cwrB2cCustomer.mobileNo);
    if (!customerResult) {
      setCart({
        ...cart,
        customer: customerResult === null ? cart.customer : customerResult,
      });
    }
    for (let index = 0; index < selectedReturnOrder.line.length; index++) {
      const item = { ...selectedReturnOrder.line[index] };
      const productItem = await getProductData(item.product.mProductId);
      if (productItem) {
        productItem.realPrice = item.grossstd;
        productItem.salePrice = item.grossstd;
        productItem.mrpPrice = item.grossstd;
        productItem.sOrderReturnId = item.sOrderId;
        productItem.sOrderlineReturnId = item.sOrderlineID;
        addProduct(productItem, -item.qtyToReturn);
        if (
          db.logConfiguration.toArray().then((fetched) => {
            fetched.map((item) => {
              item.salesReturn.filter((item) => item.log === "Y").length > 0;
            });
          })
        ) {
          posLogActivity(productItem, "SLR");
        }
        await new Promise((r) => setTimeout(r, 900));
      }
    }

    setKeyValue("0");
  };

  const getCustomerData = (mobileNo) => {
    const authHeaders = getOAuthHeaders();
    if (authHeaders && authHeaders.access_token) {
      return new Promise(function (resolve) {
        Axios({
          url: serverUrl,
          method: "POST",
          data: {
            query: `query{
              customerSearch(mobileNo:"${mobileNo}"){
                cwrCustomerId 
                code   
                name
                email        
                mobileNo
                name
                pincode
                retlLoyaltyBalance
                b2cRegisteredstoreId
                 iscredit
                balancepoints
                loyaltyLevel{
                    cwrLoyaltyLevelId
                    name
                    accumulationRate
                    redemptionRate
                }
                sCustomer{
                  sCustomerID
                  customerCategory{
                    sCustomerCateforyId
                    value
                    name
                    description
                  }
                }
                csBunit{
                  csBunitId
                  name
                }
                b2cCustomerSegment{
                  name
                  cwrB2CCustomerSegmentId
                }
              }
            }`,
          },
          headers: {
            "Content-Type": "Application/json",
            Authorization: `${authHeaders.access_token}`,
          },
        })
          .then((response) => {
            if (response.data.data.customerSearch !== null && response.data.data.customerSearch.length > 0) {
              const result = response.data.data.customerSearch;
              resolve(result[0]);
            } else {
              console.warn("No customer found, try searching with different customer");
              resolve(null);
            }
          })
          .catch(() => {
            resolve(null);
          });
      });
    }
  };

  const getProductData = (data) => {
    return new Promise(function (resolve) {
      db.products
        .where("mProductId")
        .equalsIgnoreCase(data)
        .toArray()
        .then((product) => {
          if (product.length > 0) {
            const obj = { ...product[0] };
            if (obj.overRideTax === "Y" && obj.sunitprice <= obj.overRideCondition) {
              // prettier-ignore
              const originalPrice = obj.sunitprice - (obj.sunitprice - (obj.sunitprice * (100 / (100 + obj.taxRate))));
              const taxedPrice = originalPrice + (originalPrice * obj.contraRate) / 100;
              obj.sunitprice = taxedPrice;
              obj.cTaxId = obj.contraTaxId;
              obj.taxRate = obj.contraRate;
            }
            const productDefined = {
              batchno: null,
              description: obj.description,
              discount: 0,
              discountName: "",
              imageurl: obj.imageurl,
              isDecimal: obj.isDecimal,
              isManualQty: obj.isManualQty,
              isPromoApplicable: false,
              isReturn: true,
              mBatchId: null,
              mPricingruleId: null,
              name: obj.name,
              nettotal: 0,
              primaryOrderLine: null,
              productId: obj.mProductId,
              realPrice: obj.sunitprice,
              listPrice: obj.slistprice,
              sunitprice: obj.sunitprice,
              productSegment: obj.productSegment || "",
              returnQty: null,
              salePrice: obj.sunitprice,
              mrpPrice: obj.sunitprice,
              stock: obj.onhandQty,
              taxCategory: obj.taxCategory,
              taxName: obj.taxName,
              taxFlag: obj?.taxFlag || "N",
              tax: obj.cTaxId,
              taxAmount: 0,
              taxRate: obj.taxRate,
              uom: obj.csUomId,
              uom_name: obj.uomName,
              isDecimalQty: obj.uomData[0]?.decimal === "Y" ? true : false,
              isQtyDesimal: obj.uomData[0]?.stdprecision,
              upc: obj.upc,
              value: obj.value,
              weight: 0,
              shortDescription: obj.shortDescription,
              hsncode: obj.hsncode,
              csBunitId: obj.csBunitId,
              mProductCategoryId: obj.mProductCategoryId,
              productManufacturerId: obj.productManufacturerId,
              productBrandId: obj.productBrandId,
              batchedProduct: obj.batchedProduct,
              batchedForSale: obj.batchedForSale,
              batchedForStock: obj.batchedForStock,
              multiPrice: obj.multiPrice,
              shelfLife: obj.shelfLife,
            };
            resolve(productDefined);
          } else {
            {
              message.warning(`${t("product_search_error")}`);
            }
            resolve(null);
          }
        });
    });
  };

  const handleKey = (e) => {
    const { altKey, keyCode } = e;
    if (altKey && keyCode === 82) {
      if (posConfig.showSalesReturn === "Y") {
        setDisplayReturnOrderSearch(true);
      }
    }
  };

  useEffect(async () => {
    // Focus the input element when the component mounts
    setTimeout(() => {
      try {
        const cartDetails = JSON.parse(localStorage.getItem("cartObj"));
        if (cartDetails !== null) {
          setCart(cartDetails);
        }
      } catch (error) {
        console.error("Error parsing cart details:", error);
      }
    }, 200);
    await db.productCategories.toArray().then((res) => {
      // Map the label and value
      const mappedCategories = res.map((item) => ({
        ...item,
        label: item.value,
        value: item.mProductCategoryId,
      }));

      // Sort alphabetically by label
      const sortedCategories = mappedCategories.sort((a, b) => a.label.localeCompare(b.label));

      // Set the sorted data
      setCategoriesData(sortedCategories);
    });
  }, []);

  useEffect(() => {
    let methods = [];
    tillDataPaymentMethods.map((item) => {
      if (item.name === "Cash" && posConfig.enableCash === "Y") {
        methods.push(item);
      } else if (item.name === "Card" && posConfig.enableCard === "Y") {
        methods.push(item);
      } else if (item.name !== "Cash" && item.name !== "Card") {
        methods.push(item);
      }
    });
    setPaymentType(methods);
    if (!localStorage.getItem("salesReturn")) {
      localStorage.setItem("salesReturn", "N");
    }
    setIsInputFocused(false);

    window.addEventListener("keydown", handleKey);
    return () => {
      window.removeEventListener("keydown", handleKey);
    };
  }, []);

  const returnBillSearchInputRef = useRef();
  useEffect(() => {
    if (displayReturnOrderSearch) {
      setTimeout(() => {
        returnBillSearchInputRef.current.focus();
      }, 100);
    }
  }, [retrunSearchType, displayReturnOrderSearch, kioskUI]);

  const returnProcessRef = useRef();

  // Return Bill end
  useEffect(async () => {
    filterdParkedList?.map((item, index) => {
      item.key = uuidv4().replace(/-/g, "").toUpperCase();
    });
    let data = await db.cashInCashOut.toArray();
    setCashManagementData(data);
    // if (paymentModal === false) {
    //   productsList.map((item) => {
    //     item.selected = "N";
    //   });
    //   setProductsList(productsList);
    //   setTimeout(() => {
    //     setKeyValue("0");
    //   }, 1000);
    // }
  }, [addCashFlag, paymentModal]);

  orderHistoryDetails.map((item) => {
    item.name = item.customer.name;
    item.status = item.isSynced === 1 ? "Success" : "Pending";
  });

  const removeProductModal = () => {
    if (selectedRowKeys.length > 0) {
      deleteProduct(selectedProductInCart);
    }
  };

  const deleteProductModal = () => {
    if (cart.items.length > 0) {
      Modal.confirm({
        title: "This action will remove all products from the cart, including the selected customer.Do you wish to proceed?",
        icon: null,
        cancelText: "No",
        okText: "Yes",
        onOk() {
          deleteCart(true);
        },
      });
    }
  };

  const columns = [
    {
      title: "Parked Sale",
      dataIndex: "parkedTime",
      key: "parkedTime",
      width: "30%",
    },
    {
      title: "Customer",
      dataIndex: "customer",
      key: "customer",
      width: "28%",
    },
    {
      title: "Qty",
      dataIndex: "qty",
      key: "qty",
      width: "38%",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "19%",
    },
  ];

  const columns1 = [
    {
      title: "Description",
      dataIndex: "product",
      width: "43%",
      render: (_, record) => {
        return record.product.name;
      },
    },
    {
      title: "Unit Price",
      dataIndex: "grossstd",
      render: (_, record) => {
        return parseFloat(record.grossstd).toFixed(2);
      },
    },
    {
      title: "Qty Ordered",
      dataIndex: "qty",
    },
    {
      title: "Qty to Return",
      dataIndex: "qtyToReturn",
    },
  ];

  const orderHistoryColumns = [
    {
      title: "Document Number",
      dataIndex: "documentno",
      key: "documentno",
      width: "30%",
    },
    {
      title: "Customer",
      dataIndex: "name",
      key: "name",
      width: "28%",
    },
    {
      title: "Total Amount",
      dataIndex: "total",
      key: "total",
      width: "38%",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "19%",
    },
  ];

  const cashManagementColumn = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: "30%",
    },
    {
      title: "Customer",
      dataIndex: "customer",
      key: "customer",
      width: "30%",
    },
    {
      title: "Reasons",
      dataIndex: "note",
      key: "note",
      width: "33%",
    },
    {
      title: "Transactions(Rs)",
      dataIndex: "amount",
      key: "amount",
      width: "20%",
      render: (text, record) => {
        return record.cashAddInFlag === true ? <span style={{ color: "red" }}>-{record.amount}</span> : record.amount;
      },
    },
  ];

  // const handleKeyvalue = async (key) => {
  //   if (cart.items.length <= 0) {
  //     productsList.map((ele) => {
  //       ele.selected = "N";
  //     });
  //     setProductsList(productsList);
  //   }
  //   if (key === "0") {
  //     setSelectedPaymentMethod({});
  //     setKeyValue("0");
  //     setSelectedKeys([]);
  //   }
  //   if (key === "1") {
  //     filterdParkedList?.map((item, index) => {
  //       item.status = "Parked";
  //       item.key = uuidv4().replace(/-/g, "").toUpperCase();
  //       item.customer = item.parkedCart.customer.name;
  //       item.qty = item.parkedCart.totalQty;
  //     });
  //     setKeyValue("1");
  //     setSelectedKeys([]);
  //   }
  //   if (key === "2") {
  //     setKeyValue("2");
  //     setSelectedKeys([]);
  //   }

  //   if (key === "3") {
  //     showOrderHistory();
  //     setKeyValue("3");
  //     setSelectedKeys([]);
  //   }
  //   if (key === "4") {
  //     setLoading(true);
  //     db.products
  //       .offset(productsList.length)
  //       .limit(100)
  //       .toArray()
  //       .then((productsFetched) => {
  //         const additionalProductsFetched = productsList.concat(productsFetched);
  //         additionalProductsFetched.map((ele) => {
  //           ele.selected = "N";
  //         });
  //         setProductsList([...additionalProductsFetched]);
  //       });
  //     await processSync();
  //     setLoading(false);
  //   }
  //   if (key === "7") {
  //     setKeyValue("7");
  //     setSelectedKeys([]);
  //   }
  //   if (key === "parked-bill") {
  //     filterdParkedList?.map((item, index) => {
  //       item.status = "Parked";
  //       item.key = uuidv4().replace(/-/g, "").toUpperCase();
  //       item.customer = item.parkedCart.customer.name;
  //       item.qty = item.parkedCart.totalQty;
  //     });
  //   }
  // };

  const retrieveParkedData = (record) => {
    if (cart.items.length > 0) {
      Modal.confirm({
        title: "Save Cart Items ?",
        icon: <ExclamationCircleOutlined />,
        content: (
          <div>
            You can retrieve the bill later by selecting the 'Retrieve' option in Parked Bills.
            <br />
            Do you want to continue parking the bill?
          </div>
        ),
        okText: "Yes",
        cancelText: "No",
        onOk() {
          parkBill();
          setTimeout(() => {
            selectParkedBill(record, "management");
          }, 2000);
        },
        onCancel() {
          selectParkedBill(record, "management");
        },
      });
    } else {
      selectParkedBill(record, "management");
    }
  };

  const handleExpand = (expanded, record) => {
    if (expanded) {
      selectProduct(record);
    } else {
      setSelectedKeys([]);
    }
  };

  const changeLoginType = (e) => {
    setKioskUI(e);
    const customEvent = new CustomEvent("customStorageChange", {
      detail: { key: "kioskUI", newValue: e },
    });
    window.dispatchEvent(customEvent);
    localStorage.setItem("kioskUI", e);
  };

  let kioskProps = {
    ...props,
    setKioskUI,
    layoutName,
    setLayoutName,
    onChange,
    handleKeyboardInputs,
    keyboardRef,
    inputName,
    setInputFields,
    inputRef,
    setInputName,
    removeProductModal,
    setIsInputFocused,
    isInputFocused,
    categoriesData,
  };

  const mainDivStyles = themeJSON.appBgImageURL
    ? {
        backgroundImage: `url(${themeJSON.appBgImageURL})`,
        backgroundPosition: "100% 100%",
        backgroundSize: "cover",
        // backgroundRepeat: "no-repeat",
        height: "100vh",
        width: "100%",
        display: isLocked ? "none" : "block",
      }
    : {
        backgroundImage: `url(${kioskBGI})`,
        backgroundSize: "cover",
        backgroundPosition: "10% 10%",
        // backgroundColor: colorVariable ? colorVariable : "#989898",
        height: "100vh",
        width: "100%",
        position: "absolute",
      };

  const logoStyles = isThemeLogo
    ? { height: "8vh", position: "relative", top: "2.3vh", left: "1.2vw", cursor: "pointer", width: "5vw" } // Styles for theme logo
    : { height: "2.8vw", position: "relative", top: "2.3vh", left: "1.2vw", cursor: "pointer" }; // Styles for kisok logo

  const handleCategoryClick = () => {
    setKioskUI(3);
  };
  return (
    <Spin indicator={<LoadingOutlined style={{ fontSize: 40, marginTop: "18vh", color: "#2F3856" }} spin />} spinning={loading}>
      {kioskUI === 0 ? (
        <div style={mainDivStyles}>
          <div style={{ cursor: "pointer" }}>
            <img src={logoURL} style={logoStyles} onDoubleClick={() => setKioskUI(6)} />
          </div>
          <div style={{ paddingTop: "13vh" }}>
            <p
              // className="welcome-text"
              style={{
                // color:themeJSON.orderNoTextColor || "#fff",
                color: "#fff",
                textAlign: "center",
                textShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                fontFamily: "Inter",
                fontSize: "5.3vw",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: 1,
                margin: 0,
              }}
            >
              Welcome
            </p>
            {/* <p className="welcome-text-des">Please enter your details to sign in and proceed further</p> */}
          </div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "20px",
              color: "white",
              paddingLeft: "10px",
              paddingRight: "10px",
              marginTop: "10px",
            }}
          >
            {categoriesData.map((category) => (
              <div
                key={category.mProductCategoryId}
                style={{
                  flex: "1 1 30%",
                  minWidth: "250px",
                  padding: "20px",
                  border: "1px solid #ccc",
                  borderRadius: "8px",
                  boxShadow: "2px 2px 8px rgb(0, 0, 0.1)",
                  textAlign: "center",
                  transition: "all 0.3s ease-in-out",
                  margin: "0 10px",
                  cursor: "pointer",
                }}
                onClick={() => handleCategoryClick(category)}
              >
                <img
                  src={category.imageurl || defaultImage}
                  alt={category.name}
                  style={{
                    width: "50px",
                    height: "50px",
                    objectFit: "cover",
                    borderRadius: "5px",
                    marginBottom: "8px",
                  }}
                />
                <h3 style={{ fontSize: "18px", fontWeight: "bold", color: "white" }}>{category.name}</h3>
                <p style={{ color: "#bbb", marginTop: "8px", color: "white" }}>{category.label}</p>
              </div>
            ))}
          </div>
          {/* <Row>
            <Col xl={4} span={4}></Col>
            <Col xl={16} span={16} style={{ paddingTop: "10.7vh", display: "flex", justifyContent: "center" }}>
              <div
                className="cl-mobile-icon"
                onClick={() => {
                  deleteCart(true);
                  form.resetFields();
                  changeLoginType(1);
                }}
              >
                <img src={mobileImg} alt="mobile" className="cl-img" />
                <div style={{ fontSize: "2.1vw", marginBottom: "0.6rem", color: "#FFF", fontWeight: "500", cursor: "pointer" }}>Mobile Number</div>
              </div>
              <div className="cl-or-txt">
                <p style={{ fontSize: "27px", fontFamily: "Roboto Condensed", fontWeight: "500", color: "white" }}>Or</p>
              </div>
              <div
                className="cl-email-icon"
                onClick={() => {
                  deleteCart(true);
                  form.resetFields();
                  changeLoginType(2);
                }}
              >
                <img src={emailImg} alt="email" className="cl-img" />
                <div style={{ fontSize: "2.1vw", marginBottom: "0.6rem", color: "#FFF", fontWeight: "500", cursor: "pointer" }}>Email Address</div>
              </div>
            </Col>
            <Col span={4}></Col>
          </Row> */}
          <div className="left-bottom-images">
            <div className="bottom-img">
              <img src={volumeImg} style={{ height: "2.7vw" }} />
              <p style={{ color: "#fff", margin: "0" }}>Volume</p>
            </div>
            <div className="bottom-img">
              <img src={wheelchairImg} style={{ height: "2.7vw" }} />
              <p style={{ color: "#fff", margin: "0" }}>Accessibility</p>
            </div>
            <div className="bottom-img">
              <img src={helpImg} style={{ height: "2.7vw" }} />
              <p style={{ color: "#fff", margin: "0" }}>Help</p>
            </div>
          </div>
        </div>
      ) : kioskUI === 1 ? (
        <MobileComponent {...kioskProps} />
      ) : kioskUI === 2 ? (
        <EmailComponent {...kioskProps} />
      ) : kioskUI === 3 ? (
        <HomeComponent {...kioskProps} />
      ) : kioskUI === 4 ? (
        <Login {...kioskProps} />
      ) : kioskUI === 5 ? (
        <PaymentSelection {...kioskProps} />
      ) : kioskUI === 6 ? (
        <AdminComponent {...kioskProps} />
      ) : null}
    </Spin>
  );
};

export default Kiosk;
