import i18n from "i18next";

export const getMultilingualText = (key) => {
  const multilingual =process.env.REACT_APP_MULTILINGUAL === 'true' &&
    // localStorage.getItem("selectedLanguage") &&
    localStorage.getItem("selectedLanguage") !== "English";

  const translatedText = i18n.t(key);
  return multilingual ? `${translatedText} / ${key}` : translatedText;
};
