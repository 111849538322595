import React from "react";
import InventoryCore from "./inventoryCore";
import SuperMarketInventory from "./supermarket";

const Inventory = () => {
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const tillaccess = JSON.parse(tillData?.tillAccess?.userAccessController);
  const tillLayout = parseInt(tillaccess?.layout === null || undefined ? 1 : tillaccess?.layout);
  return tillaccess.pos === "Y" ? <InventoryCore component={SuperMarketInventory} /> : <h1>Access Denied</h1>;
};

export default Inventory;
