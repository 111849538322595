import React, { useState, useEffect, useRef } from "react";
import { Modal, Popover, Row, Col, Card, Progress, Table, Divider, Drawer, Button, Input, Menu, Spin, Badge, message, Select, DatePicker, Form, Tabs, Checkbox, Space } from "antd";
import TextArea from "antd/lib/input/TextArea";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import closeImage from "../../../assets/images/close-x.svg";
const ModalComponent = (props) => {
  const { remarksPopup, setRemarksPopup, isInputFocused, setIsInputFocused, selectedRowIndex, setInventoryOrder, remarksTextArea, setRemarksTextArea } = props;
  const [layoutName, setLayoutName] = useState("default");
  const remarksKeyboardRef = useRef(null);
  const remarksInputRef = useRef(null);

  const onChangeRemarks = (input) => {
    if (input !== "Enter") {
      setRemarksTextArea(input);
    }
  };

  const onChangeRemarksInputKeyboard = (event) => {
    const input = event.target.value;
    setRemarksTextArea(input);

    if (remarksKeyboardRef.current) {
      remarksKeyboardRef.current.setInput(input);
    }
  };

  const onEnterRemarks = () => {
    if (selectedRowIndex !== null) {
      setInventoryOrder((prev) => ({
        ...prev,
        items: prev.items.map((item, index) => (index === selectedRowIndex ? { ...item, remarks: remarksTextArea } : item)),
      }));
    }
    setRemarksPopup(false);
    setIsInputFocused(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      onEnterRemarks();
    }
  };

  const handleShift = () => {
    layoutName === "default" ? setLayoutName("shift") : setLayoutName("default");
  };

  return (
    <Drawer
      placement="bottom"
      height="53vh"
      title={
        <div
          style={{
            display: "flex",
            alignItems: "center",
            height: "2vh",
            fontSize: "1vw",
          }}
        >
          <p
            style={{
              fontSize: "1.5em",
              marginTop: "3vh",
              fontWeight: "500",
              color: "#0F0718",
              flex: 1,
              textAlign: "center",
            }}
          >
            Add Notes for Item
          </p>
          <img
            src={closeImage}
            onClick={() => {
              setRemarksPopup(false);
              setIsInputFocused(false);
            }}
            // style={{ marginLeft: "auto", paddingBottom: "4vh", cursor: "pointer" }}
            style={{
              marginLeft: "auto",
              paddingBottom: "4vh",
              paddingTop: "2vh",
              cursor: "pointer",
              width: "1.5vw",
            }}
          />
        </div>
      }
      closeIcon={null}
      className="filter"
      bodyStyle={{ paddingTop: 0 }}
      visible={remarksPopup}
      closable={true}
      onClose={() => {
        setRemarksPopup(false);
        setIsInputFocused(false);
      }}
      footer={null}
    >
      <Row style={{ padding: "0 5vw", fontSize: "1vw" }}>
        <Col span={11}>
          <TextArea
            id="notes-input"
            ref={remarksInputRef}
            style={{ resize: "none" }}
            maxLength={200}
            onKeyDown={handleKeyDown}
            onChange={(e) => onChangeRemarksInputKeyboard(e)}
            value={remarksTextArea}
            rows={11}
          />
          <span
            style={{
              fontFamily: "Inter",
              fontSize: "0.8em",
              color: "#929098",
              fontWeight: 500,
            }}
          >
            Max Characters : 200
          </span>
        </Col>
        <Col span={12} offset={1}>
          <Keyboard
            theme={"hg-theme-default aprvl"}
            onChange={(input) => onChangeRemarks(input)}
            keyboardRef={(r) => (remarksKeyboardRef.current = r)}
            onKeyPress={(button) => {
              if (button === "{shift}" || button === "{lock}") handleShift();
              if (button === "{number}") {
                setLayoutName("number");
              }
              if (button === "{numbers}") {
                setLayoutName("numbers");
              }
              if (button === "{small}") {
                setLayoutName("shift");
              }
              if (button === "{caps}") {
                setLayoutName("default");
              }
              if (button === "{enter}") {
                onEnterRemarks();
              }
            }}
            layoutName={layoutName}
            display={{
              "{abc}": "ABC",
              "{smileys}": "\uD83D\uDE03",
              "{back}": "Enter",
              "{numbers}": "123",
              "{number}": "123",
              "{bksp}": "backspace",
              "{backspace}": "⌫",
              "{shift}": "⇧",
              "{tab}": "tab",
              "{lock}": "lock",
              "{enter}": "Enter",
              "{space}": "Space",
              "{caps}": "ABC",
              "{small}": "abc",
            }}
            layout={{
              shift: ["q w e r t y u i o p", "a s d f g h j k l", "{shift} z x c v b n m {backspace}", "{number} {space} {enter}"],
              numbers: ["1 2 3 4 5 6 7 8 9 0", "! @ # $ % ^ & * ( )", "- . , _ / ` {backspace}", "{caps} {space} {enter}"],
              number: ["1 2 3 4 5 6 7 8 9 0", "! @ # $ % ^ & * ( )", "- . , _ / ` {backspace}", "{small} {space} {enter}"],
              default: ["Q W E R T Y U I O P", "A S D F G H J K L", "{shift} Z X C V B N M {backspace}", "{numbers} {space} {enter}"],
            }}
          />
        </Col>
      </Row>
    </Drawer>
  );
};

export default ModalComponent;
