import React, { useState, useEffect } from "react";
import { Modal, Input, Row, Col, Button, Tag } from "antd";
import SearchIcon from "../../../assets/images/searchIcon.svg";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";

const ModalComponent = (props) => {
  const { productData, visibleProductsModal, setVisibleProductsModal, cart, setCart } = props;
  const itemsPerPage = 10;

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredProducts, setFilteredProducts] = useState(productData);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    const filtered = productData.filter((item) => {
      const lowerQuery = searchQuery.toLowerCase();
      return item.name.toLowerCase().includes(lowerQuery) || item.value.toLowerCase().includes(lowerQuery) || (item.upc && item.upc.toLowerCase().includes(lowerQuery));
    });

    setFilteredProducts(filtered);
    setCurrentPage(0);
  }, [searchQuery, productData]);

  // Paginate filtered results
  const paginatedData = filteredProducts.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);

  const toggleCart = (product) => {
    const isInCart = cart.some((item) => item.m_product_id === product.m_product_id);
    if (isInCart) {
      setCart(cart.filter((item) => item.m_product_id !== product.m_product_id));
    } else {
      setCart([...cart, { ...product, qty: 1, total: product.lastpurchaseprice, lineId: uuidv4().replace(/-/g, "").toUpperCase() }]);
    }
  };

  return (
    <Modal visible={visibleProductsModal} centered={true} width={"68%"} closable={false} footer={null} onCancel={() => setVisibleProductsModal(false)}>
      <div style={{ padding: "3vh 0 0 0", height: "81vh", position: "relative" }}>
        {/* Search Input */}
        <Input
          placeholder="Search Item Code / Description"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          style={{
            borderRadius: "7px",
            padding: "0.8vh 0.8vw",
            border: "1px solid #d9d9d9",
            width: "100%",
          }}
          prefix={<img src={SearchIcon} alt="Search" />}
        />

        {/* Product List */}
        <Row style={{ padding: "2vh 0" }} gutter={[16, 16]}>
          {paginatedData.length > 0 ? (
            paginatedData.map((item, index) => (
              <Col key={index} span={12} style={{ padding: "0 8px" }}>
                <Row style={{ padding: "1.5%", width: "100%", border: "1px solid #d9d9d9", height: "10vh" }}>
                  <Col span={12}>
                    {/* Truncate long product names */}
                    <p
                      title={item.name}
                      style={{
                        margin: 0,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "100%",
                      }}
                    >
                      {item.name}
                    </p>
                    <p style={{ margin: 0 }}>
                      {item.lastpurchaseprice} - {item.uomname}
                    </p>
                  </Col>
                  {/* <Col span={12} style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                    <Button
                      style={{
                        backgroundColor: "#2F3856",
                        color: "#fff",
                        width: "6.5vw",
                        height: "6vh",
                      }}
                    >
                      + Add
                    </Button>
                  </Col> */}
                  <Col span={12} style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                    {cart.some((cartItem) => cartItem.m_product_id === item.m_product_id) ? (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Tag style={{ marginRight: "10px", borderRadius: "15px" }}>In Cart</Tag>
                        <Button
                          onClick={() => toggleCart(item)}
                          style={{
                            backgroundColor: "#f5f5f5",
                            color: "#000",
                            border: "1px solid #d9d9d9",
                          }}
                          icon={<DeleteOutlined />}
                        >
                          Remove
                        </Button>
                      </div>
                    ) : (
                      <Button
                        onClick={() => toggleCart(item)}
                        style={{
                          backgroundColor: "#2F3856",
                          color: "#fff",
                          width: "6.5vw",
                          height: "6vh",
                        }}
                        icon={<PlusOutlined />}
                      >
                        Add
                      </Button>
                    )}
                  </Col>
                </Row>
              </Col>
            ))
          ) : (
            <p style={{ textAlign: "center", width: "100%" }}>No products found</p>
          )}
        </Row>

        {/* Pagination Controls */}
        <Row style={{ position: "absolute", bottom: "0vh", width: "100%" }}>
          <Col span={8} style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
            <Button
              disabled={currentPage === 0}
              onClick={() => setCurrentPage((prev) => prev - 1)}
              style={{
                textAlign: "center",
                backgroundColor: currentPage === 0 ? "#ccc" : "#2F3856",
                width: "4vw",
                height: "6.5vh",
                color: "#fff",
                borderRadius: "5px",
              }}
            >
              {"<"}
            </Button>
          </Col>

          <Col span={8} style={{ textAlign: "center", fontWeight: "bold" }}>
            Page {currentPage + 1} of {Math.ceil(filteredProducts.length / itemsPerPage)}
          </Col>

          <Col span={8} style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
            <Button
              disabled={(currentPage + 1) * itemsPerPage >= filteredProducts.length}
              onClick={() => setCurrentPage((prev) => prev + 1)}
              style={{
                textAlign: "center",
                backgroundColor: (currentPage + 1) * itemsPerPage >= filteredProducts.length ? "#ccc" : "#2F3856",
                width: "4vw",
                height: "6.5vh",
                color: "#fff",
                borderRadius: "5px",
              }}
            >
              {">"}
            </Button>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default ModalComponent;
