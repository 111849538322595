import React from "react";
import { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import Axios from "axios";
import { getOAuthHeaders } from "../../../constants/oAuthValidation";
import { LoadingOutlined, EnvironmentOutlined, CloseSquareFilled } from "@ant-design/icons";
import SalesHistoryIcon from "../../PointOfSale/Retail/SvgComponent/SalesHistoryIcon";
import POSLogo from "../../../assets/images/NewLogoCW.svg";
import SearchIcon from "../../../assets/images/searchIcon.svg";
import { Row, Col, Input, Table, DatePicker, Button, Spin, Select, InputNumber } from "antd";
import TextArea from "antd/lib/input/TextArea";
import sideMenu from "../../../assets/images/side-menu.svg";
import LockIcon from "../../../assets/images/lock-icon.svg";
import userIcon from "../../../assets/images/userId.svg";
import Online from "../../../assets/images/green.svg";
import Ofline from "../../../assets/images/red.svg";
import FilterIcon from "../../../assets/images/filterNewICon.svg";
import NotesImage from "../../../assets/images/notes.svg";
import DeleteProduct from "../../../assets/images/delete1.svg";
import NoteEditIcon from "../../../assets/images/NoteEditIcon.svg";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";

const SuperMarketPurchaseOrder = (props) => {
  const { Option } = Select;
  const { loading, colorVariable, formattedDateTime, supplierData, selectedSupplier, setSelectedSupplier, selectSupplier, cart, setCart, tillData, access_token, userData } = props;
  const SSOURL = process.env.REACT_APP_SSOURL;

  const handleQtyChange = (value, record) => {
    setCart((prevCart) =>
      prevCart.map((item) =>
        item.m_product_id === record.m_product_id
          ? {
              ...item,
              qty: value,
              total: value * item.lastpurchaseprice,
            }
          : item
      )
    );
  };

  const CompletePurchaseOrder = async () => {
    const CartLines = [];
    cart.map((cartLine) => {
      CartLines.push({
        pOrderLineId: cartLine.lineId,
        productID: cartLine.m_product_id,
        qty: cartLine.qty,
        productCode: cartLine.value,
        productName: cartLine.name,
        category: "All Products",
        unitPrice: cartLine.lastpurchaseprice,
      });
    });
    const purchaseOrderDataLoad = {
      pOrderId: uuidv4().replace(/-/g, "").toUpperCase(),
      csBunitId: tillData?.tillAccess?.csBunit?.csBunitId,
      sourcebname: "Excel",
      pSupplierId: selectedSupplier.recordid,
      supplierName: selectedSupplier.name,
      dateOrdered: moment().format("YYYY-MM-DD"),
      remarks: null,
      created: moment().format("YYYY-MM-DD HH:mm:ss"),
      createdby: userData?.user_id,
      createdbyName: userData?.name,
      updated: moment().format("YYYY-MM-DD HH:mm:ss"),
      updatedby: userData?.user_id,
      updatedbyName: userData?.name,
      lines: CartLines,
    };

    const dataLoad = JSON.stringify(purchaseOrderDataLoad).replace(/"/g, '\\"');

    const purchaseOrderQuery = {
      query: `mutation {
          transactionQueue(queue: {
            transactionQueueId:"${uuidv4().replace(/-/g, "").toUpperCase()}"
            transactionType:"POR"
            action:"Process"
            dataLoad:${dataLoad}
           }) { 
        code
        message   
        referenceNo
        recordId
        }
        }`,
    };
    const purchaseOrderRes = await Axios({
      method: "POST",
      url: SSOURL,
      data: purchaseOrderQuery,
      headers: {
        "Content-Type": "application/json",
        Authorization: `${access_token}`,
      },
    });
  };

  return (
    <Spin indicator={<LoadingOutlined style={{ fontSize: 40, marginTop: "18vh", color: "#2F3856" }} spin />} spinning={loading}>
      <div style={{ background: colorVariable || "#f3f4f9", height: "100vh" }}>
        <>
          <Row
            style={{
              padding: "0.5% 0",
              height: "9vh",
              alignItems: "center",
              fontSize: "1vw",
            }}
          >
            <Col style={{ cursor: "pointer" }} span={1}>
              <img src={sideMenu} style={{ paddingLeft: "1rem", height: "2vw" }} />
            </Col>
            <Col span={3}>
              <img src={POSLogo} style={{ height: "2.5vw", paddingLeft: "0.4vw", cursor: "pointer" }} />
            </Col>
            <Col
              span={4}
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  fontFamily: "Inter",
                  fontWeight: 600,
                  fontSize: "1.5em",
                  color: "#0F0718",
                  borderRadius: "2px",
                  marginTop: "0.45rem",
                  textAlign: "center",
                }}
              >
                Purchase Order
              </span>
            </Col>
            <Col
              span={6}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "#0F0718",
                  fontFamily: "Inter",
                  fontWeight: 500,
                  fontSize: "1em",
                  paddingLeft: "1vw",
                }}
              >
                Indent No 1234
              </span>
            </Col>
            <Col span={1} />

            <Col
              span={2}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingLeft: "6vw",
                cursor: "pointer",
              }}
            >
              {/* <SalesHistoryIcon color={"#0F0718"} /> */}
            </Col>
            {/* <Col span={2} /> */}
            <Col
              span={4}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  fontFamily: "Inter",
                  fontWeight: 500,
                  fontSize: "1em",
                  color: "#929098",
                  marginLeft: "1.5vw",
                  marginRight: "1vw",
                }}
              >
                {" "}
                {formattedDateTime}
              </span>
            </Col>
            <Col
              span={4}
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <img
                //   onClick={() => {
                //     setIsLocked(true);
                //   }}
                src={LockIcon}
                style={{ height: "3vh", cursor: "pointer", marginRight: "0.7rem" }}
              />
              <div
                style={{
                  display: "flex",
                  alignItems: "end",
                  justifyContent: "center",
                  padding: "0.5vw",
                  border: "1px solid #3C424B",
                  borderRadius: "50%",
                }}
              >
                <img src={userIcon} style={{ height: "2.7vh" }} />
              </div>
              <div
                style={{
                  marginLeft: "1vw",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <span
                  style={{
                    color: "#0F0718",
                    fontSize: "1em",
                    fontFamily: "Inter",
                    fontWeight: "500",
                    wordWrap: "break-word",
                  }}
                >
                  {/* {userData.user} */}
                </span>
                <span
                  style={{
                    color: "#0F0718",
                    fontSize: "0.8em",
                    fontFamily: "Inter",
                    fontWeight: "500",
                    wordWrap: "break-word",
                  }}
                >
                  Till ID : {JSON.parse(localStorage.getItem("tillValue")).name}
                </span>
              </div>
              <div style={{ marginLeft: "3%" }}>
                <img
                  src={Online}
                  style={{
                    display: !navigator.onLine && "none",
                    height: "2.5vh",
                    cursor: "pointer",
                    paddingBottom: "0.5vh",
                  }}
                />{" "}
                &nbsp;
                <img
                  src={Ofline}
                  style={{
                    display: navigator.onLine && "none",
                    height: "2.5vh",
                    cursor: "pointer",
                    paddingBottom: "0.5vh",
                  }}
                />{" "}
                &nbsp;
              </div>
            </Col>
          </Row>

          <div style={{ display: "flex", height: "91vh" }}>
            <div style={{ width: "63.9%" }}>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  padding: "0 0 0 1.7%",
                }}
              >
                <div style={{ width: "98.3%" }}>
                  <Input
                    placeholder="Search Item Code/Description"
                    id="sm-product-search"
                    className="productSearch-sm"
                    style={{
                      borderRadius: "7px",
                      padding: "0.8vh 0.8vw",
                      borderColor: "#fff",
                    }}
                    suffix={
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      ></div>
                    }
                    prefix={<img src={SearchIcon} alt="" />}
                  />
                </div>
              </div>

              <div style={{ padding: "1.5%" }}>
                <Table
                  className="sm-cart-table-pos"
                  style={{
                    width: "100%",
                    overflowY: "auto",
                    border: "none",
                    borderRadius: "7px",
                    padding: "0px 7px",
                    height: "56.5vh",
                    background: "#fff",
                  }}
                  pagination={false}
                  sticky
                  id="sm-cart-table"
                  columns={[
                    {
                      title: "Item",
                      dataIndex: "name",
                      key: "name",
                      width: "35%",
                    },
                    {
                      title: "UOM",
                      dataIndex: "uomname",
                      key: "uomname",
                      width: "15%",
                    },
                    {
                      title: "Price",
                      dataIndex: "lastpurchaseprice",
                      key: "lastpurchaseprice",
                      width: "16%",
                      align: "center",
                    },
                    {
                      title: "Qty",
                      dataIndex: "qty",
                      key: "qty",
                      width: "16%",
                      align: "center",
                      render: (text, record) => <InputNumber value={record.qty} onChange={(value) => handleQtyChange(value, record)} />,
                    },
                    {
                      title: "Total",
                      dataIndex: "total",
                      key: "total",
                      width: "12%",
                      align: "center",
                      render: (text) => `₹${text.toFixed(2)}`,
                    },
                    {
                      title: "Stock",
                      dataIndex: "qtyOnHand",
                      key: "qtyOnHand",
                      width: "12%",
                      align: "center",
                    },
                    {
                      title: "Action",
                      dataIndex: "",
                      key: "",
                      width: "10%",
                      render: (text, record, index) => {
                        return (
                          <div style={{ position: "relative", display: "flex", justifyContent: "space-evenly" }}>
                            <img
                              src={DeleteProduct}
                              onClick={(e) => {
                                e.stopPropagation();
                                setCart((prevCart) => prevCart.filter((item) => item.m_product_id !== record.m_product_id));
                              }}
                              id="sm-product-delete"
                              style={{
                                height: "2.5vh",
                                cursor: "pointer",
                                right: 1,
                                top: "15%",
                              }}
                            />
                          </div>
                        );
                      },
                      align: "center",
                    },
                  ]}
                  rowKey="key"
                  loading={false}
                  dataSource={cart}
                />
              </div>
              <div style={{ padding: "0 1.5% 0 1.5%", display: "flex" }}>
                <div
                  style={{
                    background: "#fff",
                    height: "23.5vh",
                    width: "100%",
                    border: "1px solid #fff",
                    borderRadius: "7px",
                    padding: "0.5vh",
                    fontSize: "1vw",
                  }}
                >
                  <Row style={{ padding: "1% 1.5% 1% 1.5%" }}>
                    <Col span={12} style={{ textAlign: "left", fontSize: "1.2em" }}>
                      Subtotal:
                    </Col>
                    <Col span={12} style={{ textAlign: "right", fontSize: "1.2em" }}>
                      0.00
                    </Col>
                    <Col span={12} style={{ textAlign: "left", fontSize: "1.2em" }}>
                      Total Items:
                    </Col>
                    <Col span={12} style={{ textAlign: "right", fontSize: "1.2em" }}>
                      0
                    </Col>
                    <Col span={12} style={{ textAlign: "left", fontSize: "1.2em" }}>
                      Total Qty:
                    </Col>
                    <Col span={12} style={{ textAlign: "right", fontSize: "1.2em" }}>
                      0.00
                    </Col>
                  </Row>
                  <Row justify="end" style={{ paddingRight: "1.5%" }}>
                    <Col>
                      <Button
                        style={{
                          backgroundColor: "#2F3856",
                          borderRadius: "5px",
                          height: "7vh",
                          width: "12.5vw",
                          color: "#fff",
                          textAlign: "center",
                        }}
                        onClick={CompletePurchaseOrder}
                      >
                        Complete Purchase
                      </Button>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>

            <div
              style={{
                width: "35.6%",
              }}
            >
              <div
                style={{
                  padding: "0% 0% 0 0.4%",
                  borderRadius: "7px",
                  paddingBottom: "0",
                  height: "39vh",
                  marginRight: "1%",
                  backgroundColor: "#fff",
                  position: "relative",
                  fontSize: "1vw",
                }}
              >
                <Row
                  style={{
                    padding: "3% 2% 0 3%",
                  }}
                >
                  <Col span={23} offset={1} style={{ fontSize: "2em", color: "#0F0718" }}>
                    Choose a Supplier
                  </Col>
                  <Col span={23} offset={1}>
                    <Select className="supplier" style={{ width: "30vw" }} placeholder="Select Supplier" onChange={selectSupplier}>
                      {supplierData.map((md) => (
                        <Option value={md.recordid}>{md.name}</Option>
                      ))}
                    </Select>
                  </Col>
                </Row>
              </div>

              <div
                style={{
                  width: "100%",
                  marginTop: "1.5vh",
                  padding: "0% 0% 0 0.4%",
                  borderRadius: "7px",
                  height: "24vh",
                  marginRight: "1%",
                  backgroundColor: "#fff",
                  position: "relative",
                  fontSize: "1vw",
                }}
              >
                <Row
                  style={{
                    padding: "3% 2% 0 3%",
                  }}
                >
                  <Col span={20} offset={1} style={{ fontSize: "2em", color: "#0F0718" }}>
                    Purchase Remarks
                  </Col>
                  <Col
                    span={3}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                    }}
                  >
                    <img src={NoteEditIcon} style={{ height: "4vh" }} />
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </>
      </div>
    </Spin>
  );
};

export default SuperMarketPurchaseOrder;
