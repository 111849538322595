import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// import englishTranslations from './locales/popUpMessages/English.json';
// import arabicTranslations from './locales/popUpMessages/Arabic.json';
import englishTranslations from '../locales/app/en/translation.json';
import arabicTranslations from '../locales/app/ar/translation.json';
import frenchTranslation from '../locales/app/fr/translation.json'
import hindiTranslations from '../locales/app/hi/translation.json';

const defaultLanguage = process.env.REACT_APP_LANGUAGE || 'English';
const storedLanguage = localStorage.getItem('selectedLanguage') || defaultLanguage;

i18n.use(initReactI18next).init({
    lng: storedLanguage,
    fallbackLng: defaultLanguage,
    resources: {
        English: { translation: englishTranslations },
        Arabic: { translation: arabicTranslations },
        French:{translation:frenchTranslation},
        Hindi: { translation: hindiTranslations },
    },
});

export default i18n;
